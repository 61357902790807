<template>
    <section class="pa-0 formular">
        <!--SAVE BUTTON TOOLBAR-->
        <div class="toolbar-buttons">
            <v-btn v-if="checkPrivileg && edit" dark small depressed text @click="submit('saveItem')">
                <v-icon left>mdi-content-save-outline</v-icon>
                <span>{{ $t('common.save') }}</span>
            </v-btn>
        </div>
        <AdvancedMenu :items="advancedMenu" :handle="handleClick" v-if="checkPrivileg && edit"/>

        <v-container fluid class="pb-15 px-6 py-6">

            <v-row :class="{ 'rgg-grey' : $vuetify.theme.dark }" class="grey lighten-4 rounded mb-2 pa-1 pb-3">
                <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                    {{ $t('products_packages.dialog.agreements_list') }}
                </v-col>
                <v-col cols="12" class="pa-0" v-for="agreement in getAgreements" :key="agreement.id" @click="showAgreementDetail(agreement)">
                    <Card :item="agreement" />
                </v-col>
            </v-row>
        </v-container>

        <!--FOOTER-->
        <v-container fluid :class="this.$vuetify.theme.dark ? 'black' : 'grey lighten-2'" style="position: fixed; bottom: 0; width: 100%; text-align: center;">
            <v-btn color="gold" dark small @click="downloadInvoice">
                <v-icon left>mdi-cloud-download</v-icon>
                <span>{{ $t('products_packages.dialog.download_invoice') }}</span>
            </v-btn>
        </v-container>

        <v-dialog v-model="dialog_agreement" fullscreen>
            <v-card v-if="dialog_agreement">
                <v-toolbar dark tile dense color="gold" style="position: sticky; top: 0; z-index: 10; flex: unset;">
                    <v-btn
                        icon dark
                        @click="dialog_agreement = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ agreement_item.id }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                    <AgreementDialog :id="agreement_item.id" edit/>
                </v-card-text>
            </v-card>

        </v-dialog>

    </section>
</template>

<script>
import AuthMixin from "@/services/auth/auth_mixin";
import Permissions from "@/services/auth/permissions";
import Card from "@/components/cards/PackageAgreementCard";
import AgreementDialog from "@/components/dialogs/AgreementDialog";
import AdvancedMenu from "@/components/AdvancedMenu";
import {
    deactivatePackage, deletePackage,
    downloadPackageInvoice, reactivatePackage,
    sendPackageActivationMail,
    sendPackagePurchaseMail
} from "@/api/packages";

export default {
    name: "PackageDialog",
    components: {
        Card,
        AgreementDialog,
        AdvancedMenu
    },

    props: {
        edit: Boolean,
        id: undefined,
        card_item: Object
    },
    mixins: [AuthMixin],
    data: () => ({
        dialog_agreement: false,
        agreement_item: null,
        filter: {
            page: 1,
            limit: 20,
            toolbar_filter: {}
        },
    }),

    watch: {
        '$vuetify.breakpoint.width': function () {
            this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.toolbar_title})
        },
    },

    computed: {
        toolbar_title() {
            if (this.edit) {
                if (this.$vuetify.breakpoint.width < 451) {
                    return this.id;
                } else {
                    return this.card_item.type.toUpperCase() + " - " + this.id
                }
            } else {
                return this.edit;
            }
        },

        checkPrivileg() {
            if (this.checkPermission(Permissions.ADMINISTRATOR)) {
                return true;
            }

            return !this.edit;
        },
        getAgreements() {
            return this.$store.getters.getAgreements.items;
        },
        advancedMenu() {
            return [
                {
                    label: 'send_package_purchase_mail', click() {
                        this.$root.$emit('overlay', true);
                        sendPackagePurchaseMail(this.id).then(() => {
                            this.$root.$emit('overlay', false);
                            this.closeDialog();
                            this.$root.$emit('reload');
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: 'products_packages.email_successfully_sent'
                            })
                        })
                    }
                },
                {
                    label: 'send_package_activation_mail', click() {
                        this.$root.$emit('overlay', true);
                        sendPackageActivationMail(this.id).then(() => {
                            this.$root.$emit('overlay', false);
                            this.closeDialog();
                            this.$root.$emit('reload');
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: 'products_packages.email_successfully_sent'
                            })
                        })
                    }
                },
                {
                    disable_item: this.card_item.active || this.card_item.inactive,
                    label: 'deactivate_package', click() {
                        this.$root.$emit('overlay', true);
                        this.closeDialog();
                        deactivatePackage(this.id).then(() => {
                            this.$root.$emit('reload');
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: 'products_packages.package_deactivated'
                            })
                        })
                    }
                },
                {
                    disable_item: !this.card_item.inactive,
                    label: 'reactivate_package', click() {
                        this.$root.$emit('overlay', true);
                        this.closeDialog();
                        reactivatePackage(this.id).then(() => {
                            this.$root.$emit('reload');
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: 'products_packages.package_reactivated'
                            })
                        })
                    }
                },
                {
                    disable_item: this.card_item.active || this.card_item.inactive,
                    label: 'delete_package', click() {
                        this.$root.$emit('overlay', true);
                        this.closeDialog();
                        deletePackage(this.id).then(() => {
                            this.$root.$emit('reload');
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: 'products_packages.package_deleted'
                            })
                        })
                    }
                }
            ]
        }
    },
    methods: {
        handleClick(index) {
            this.advancedMenu[index].click.call(this);
        },

        closeDialog() {
            if (this.edit) {
                this.$parent.$emit('action', {func: 'close', data: null});
            } else {
                this.$parent.$emit('call', {func: 'close', data: null});
            }
        },

        showAgreementDetail(_item) {
            this.agreement_item = _item;
            this.dialog_agreement = true;
        },

        downloadInvoice() {
            this.$root.$emit('overlay', true);
            downloadPackageInvoice(this.id).then(() => {
                this.$root.$emit('overlay', false);
            })
        }
    },

    updated() {
        this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.toolbar_title});
    },

    mounted() {
       this.$store.dispatch("getAgreementsForPackage", this.id);
    },
    destroyed() {
        this.$store.dispatch('clearAgreements');
    }
}
</script>